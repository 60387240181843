var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: { name: _vm.name },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Выбор другого времени")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("el-row", { class: { "flex-w": _vm.isMobile } }, [
        _c("div", { staticClass: "warning-header" }, [
          _c("i", { staticClass: "el-icon-warning" }),
          _c("span", [_vm._v("Внимание!")]),
        ]),
        _c("div", { staticClass: "warning-content" }, [
          _vm._v(" На указанное вами время и позже нет свободных тайм слотов "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "btn-container" },
        [
          _c(
            "iq-button",
            {
              attrs: { color: "main-dark", size: "fullWidth" },
              on: { onClick: _vm.newTimeslotTime },
            },
            [
              _c("i", { staticClass: "el-icon-time button-icon" }),
              _vm._v(" Выбрать более раннее время "),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "22px", width: "100%" } },
            [
              _c(
                "iq-button",
                {
                  attrs: { color: "gray", size: "fullWidth" },
                  on: { onClick: _vm.closeModal },
                },
                [_vm._v(" Отмена ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }