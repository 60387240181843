<template>
  <Dialog :name="name">
    <template #title>
      <div>Выбор другого времени</div>
    </template>

    <el-row :class="{ 'flex-w': isMobile }">
      <div class="warning-header">
        <i class="el-icon-warning"/>
        <span>Внимание!</span>
      </div>
      <div class="warning-content">
        На указанное вами время и позже нет свободных тайм слотов
      </div>
    </el-row>
    <div class="btn-container">
      <iq-button
        color="main-dark"
        size="fullWidth"
        @onClick="newTimeslotTime"
      >
        <i class="el-icon-time button-icon" />
        Выбрать более раннее время
      </iq-button>
      <div style="margin-top: 22px; width: 100%">
        <iq-button
          color="gray"
          size="fullWidth"
          @onClick="closeModal"
        >
          Отмена
        </iq-button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { TIMESLOT_SHIFT_RESET } from '@/constants/dialogs'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/views/ui/components/buttons/IqButton'
export default {
  name: 'TimeslotShiftReset',
  components: {
    Dialog,
    IqButton,
  },
  data() {
    return {
      name: TIMESLOT_SHIFT_RESET,
    }
  },
  methods: {
    newTimeslotTime() {
      this.$emit('newTimeslot')
    },
    closeModal() {
      this.setDialog({ name: TIMESLOT_SHIFT_RESET, visible: false })
    },
  },
}
</script>
<style lang="sass">
.button-icon
  margin-right: 5px
.warning-header
  display: flex
  justify-content: center
  margin-bottom: 18px
  font-size: 18px
  font-weight: bold
  line-height: 26px
  .el-icon-warning
    margin-top: 1px
    margin-right: 7px
    font-size: 20px
    color: $color-orange-primary
.warning-content
  margin-bottom: 25px
  font-size: 14px
  line-height: 22px
  text-align: center
</style>
